import styled, { css } from "styled-components";

export const Container = styled.section`

position: fixed;
backdrop-filter: blur(5px);
width: 30%;
height: 100%;
top: 0;
left: 70%;
/*tablet*/
@media (max-width: 601px){
  width: 95%;
  left: 10%
}
right: 0;
bottom: 0;
z-index: 5;   
display: inline-block;
justify-content: center;

background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.801));
opacity: 0;
pointer-events: none;
overflow-y: scroll;
overflow-x: hidden;
transform: translateX(50%);
transition: .5s;

.categories p, .categories a{
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  margin-top: 30%;
  font-size: 1.2em;
}

> img {
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: .7s;
  transform: rotate(40deg);
  width: 50px;
  border-radius: 50px;
  filter: invert(100%);
  margin-bottom: 60px;
z-index: 6;   

}

img:hover{
  cursor: pointer;
}

nav {
  margin-top: 5%;
  justify-content: center;
  transition: .7s;
}
.categories{
  margin-right: 10%;
  margin-top: 30%;
}
nav ul{
  list-style-type: none;
  height: 100%;
  width: 100%;
  display: flex;
}
nav li{
  padding: 5px;
  margin-top: 7%;
}
nav li:hover{
  background-color: #121212;
  width: 100%;
  text-align: center;
  transition: .3s;
}
nav ul li a{
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 1.2em;  
  color: #ffffff;
  font-weight: bold;
  @media (min-width: 765px) {
    font-size: 1.5em;
  }
}

${({ IsVisible }) => IsVisible && css`
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
  > img {
    transform: rotate(0deg);
  }
  nav {
    transform: scale(1);
  }
`}
`;