import { Container } from './MobileMenuStyles';
import '../ComponentsCSS/Menu.css'
import { useContext } from 'react';
import { Context } from '../context/GlobalState';
import { useState } from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";

const MenuMobile = () => {


  const { menuOn, setMenuOn } = useContext(Context);

  const [activeDrop, setActiveDrop] = useState(false);
  const [activeDropEsp, setActiveDropEsp] = useState(false);

  // const APP = ''

  // const download = (url) => {
  //   const filaName = url.split('/').pop();
  //   const atag = document.createElement('a');
  //   atag.href = url;
  //   atag.setAttribute('download', filaName);
  //   document.body.appendChild(atag);
  //   atag.click();
  //   atag.remove();
  // }

  return (
    <div className='menuOn'>
      <Container IsVisible={menuOn}>
        <nav>
          <ul>
            <div>
              <div className='categories p-4'>
                <div className={activeDrop || activeDropEsp ? 'dropdown-menu' : 'dropdown-menu-Active'}>
                  <p onClick={() => setMenuOn(false)}>
                    <a href="/">Inicio</a>
                  </p>
                  {/* <p>
                    <a href="https://www.mediafire.com/file/ljvvgw2knwkp8an/NetMoviesWin.apk/file"> Aplicativo</a>
                  </p> */}
                  {/* <p onClick={() => setMenuOn(false)}>
                    <a href="/nm/pedidos">Pedidos</a>
                  </p> */}
                  <p className='font-semibold text-2xl my-2' onClick={() => setMenuOn(false)}>
                    <a href="/series">Séries</a>
                  </p>
                </div>
                <div>
                  <div className={activeDropEsp ? 'dropdown-menu' : 'dropdown-menu-Active'}>
                    <div className='gen' onClick={() => setActiveDrop(activeDrop ? false : true)}>
                      {activeDrop ?
                        <div className='flex items-center'> <div className='ml-2'> <FaArrowLeft size={"25px"} /></div> <div className='font-semibold text-2xl ml-2 '>Voltar</div></div>
                        :
                        <div className='flex items-center'> <div className='font-semibold text-2xl'>Filmes</div> <div className='ml-2 pt-1'> <FaArrowRight size={"25px"} /></div></div>}
                    </div>
                    <ul className={activeDrop ? 'dropdown-menu-Active' : 'dropdown-menu'}>
                      <a href="/Categoria/Ação" onClick={() => setMenuOn(false)}>
                        <li>Ação</li>
                      </a>
                      <a href="/Categoria/Animação" onClick={() => setMenuOn(false)}>
                        <li>Animação</li>
                      </a>
                      <a href="/Categoria/Aventura" onClick={() => setMenuOn(false)}>
                        <li>Aventura</li>
                      </a>
                      <a href="/Categoria/Biografia" onClick={() => setMenuOn(false)}>
                        <li>Biografia</li>
                      </a>
                      <a href="/Categoria/Comédia" onClick={() => setMenuOn(false)}>
                        <li>Comédia</li>
                      </a>
                      <a href="/Categoria/Drama" onClick={() => setMenuOn(false)}>
                        <li>Drama</li>
                      </a>
                      <a href="/Categoria/Ficção científica" onClick={() => setMenuOn(false)}>
                        <li>F-Científica</li>
                      </a>
                      <a href="/Categoria/Romance" onClick={() => setMenuOn(false)}>
                        <li>Romance</li>
                      </a>
                      <a href="/Categoria/Suspense" onClick={() => setMenuOn(false)}>
                        <li>Suspense</li>
                      </a>
                      <a href="/Categoria/Terror" onClick={() => setMenuOn(false)}>
                        <li>Terror</li>
                      </a>
                    </ul>
                  </div>

                  {/* Especiais */}
                  {/* <div className={activeDrop ? 'dropdown-menu' : 'dropdown-menu-Active'}>
                    <p className='gen' onClick={() => setActiveDropEsp(activeDropEsp ? false : true)}>
                      {activeDropEsp ? '← Back' : 'Especiais →'}
                    </p>
                    <ul className={activeDropEsp ? 'dropdown-menu-Active' : 'dropdown-menu'}>
                      <a href="/Categoria/Marvel" onClick={() => setMenuOn(false)}>
                        <li>Marvel</li>
                      </a>
                      <a href="/Categoria/DC" onClick={() => setMenuOn(false)}>
                        <li>DC Comics</li>
                      </a>
                      <a href="/Categoria/Netflix" onClick={() => setMenuOn(false)}>
                        <li>Netflix</li>
                      </a>
                      <a href="/Canais" onClick={() => setMenuOn(false)}>
                        <li><p>CanaisTv</p></li>
                      </a>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </ul>
        </nav>
      </Container>
    </div >
  )
}

export default MenuMobile