import { useContext } from 'react';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { CiCircleRemove } from "react-icons/ci";
import { CiMenuFries } from "react-icons/ci";

import { Context } from '../../context/GlobalState';
import { MenuParams } from './menu.params';

const Menu = () => {
  const { menuOn, setMenuOn } = useContext(Context);
  const { searchbar, searchIconActive, handleSearch, searchRes, movieName } = MenuParams();

  return (
    <div className='border-b-2 items-center pt-4 fixed bg-red-800 to-black w-full top-0 z-50 text-white backdrop-blur'>
      <header className="container mx-auto flex justify-between items-center">
        {/* Menu Mobile */}
        <div className=''>
          <div onClick={searchbar} className='cursor-pointer'>
            {!searchIconActive ? <FaMagnifyingGlass size={'24px'} /> : <CiCircleRemove size={'24px'} />}
          </div>
        </div>
        <>
          <h1 className="text-2xl italic uppercase font-serif text-white"><a href="/">Netmovies</a></h1>
        </>
        {menuOn ?
          <div className='cursor-pointer' onClick={() => setMenuOn(false)}>
            <CiCircleRemove size={"24px"} />
          </div> :
          <div className='cursor-pointer' onClick={() => setMenuOn(true)}>
            <CiMenuFries size={"24px"} />
          </div>}
      </header>

      <div className='p-2 duration-300 ease-in-out' style={{ overflowY: 'auto', maxHeight: '300px' }}>
        <div>
          {searchIconActive ? (
            <input
              type="search"
              placeholder="Buscar Filmes e séries"
              className="p-2 text-black"
              onChange={(e) => handleSearch(e)} />
          ) : null}
          {movieName && movieName.length > 0 ? (
            searchRes.length > 0 ? (
              searchRes.map(movieSearch => (
                <div key={movieSearch._id}>
                  <a href={movieSearch.temporadas ? `/serie/${movieSearch.nome}` : `/movie/${movieSearch.nome}`}>
                    <p className='hover:scale-y-125 inline-block'>{movieSearch.nome}</p>
                  </a>
                </div>
              ))
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )
          ) : null}
        </div>
      </div>

    </div>
  );
};

export default Menu;
