import React, { useState } from 'react';
import { useTvShowPlayService } from './tv-show-play.service';

export const TvShowPlayParams = (name) => {

    const { dataTvShow } = useTvShowPlayService(name);

    return {
        dataTvShow
    };
};
