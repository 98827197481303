import React, { useState, useEffect } from 'react';
import { BASE_PRO } from '../../env';

export const useTvShowPlayService = (name) => {
    console.log('name',name);
    const [dataTvShow, setDataTvShow] = useState(null);
    const [error, setError] = useState(null);

    const fetchDataCategoryMovie = async () => {
        try {
            const res = await fetch(`${BASE_PRO}/tv-shows?tvshowname=${name}`, {
                method: 'GET',
            });

            if (!res.ok) {
                throw new Error('Failed to fetch data');
            }

            const categoryData = await res.json();
            console.log('categoryData',categoryData);
            setDataTvShow(categoryData.tvShows[0]);
            setError(null);
        } catch (error) {
            console.error('Error fetching category data:', error);
            setError('Error fetching data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchDataCategoryMovie();
    }, [name]);

    return {
        dataTvShow,
        error
    };
};
