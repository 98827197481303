import { Pagination } from "@mui/material";
import { useTvShowParams } from './tv-show.params';



const TvShowDetails = () => {

    const { dataCategory, handlePageClick, currentPage, totalPage } = useTvShowParams();
    console.log('dataCategory', dataCategory);
    if (dataCategory && dataCategory.tvShows) {
        return (
            <div className='p-2 mt-20'>
                <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                    {dataCategory.tvShows.map((movie, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <p className='text-center max-w-[200px] overflow-hidden whitespace-nowrap' style={{ textOverflow: 'ellipsis' }}>{movie.nome}</p>
                            <a href={`/serie/${movie.nome}`}>
                                <img src={movie.img} alt='capaFilme' className="w-full h-auto object-cover rounded-lg hover:opacity-80" style={{ maxHeight: '300px' }} />
                            </a>
                            <p>{movie.data}</p>
                        </div>
                    ))}
                </div>

                <div className='text-center justify-center m-auto flex my-5'>
                    <Pagination
                        color="primary"
                        shape="rounded"
                        size="large"
                        siblingCount={2}
                        page={currentPage}
                        count={totalPage}
                        onChange={(e, page) => handlePageClick(page)}
                        sx={{
                            '& .MuiPaginationItem-root': {
                                color: 'white',
                            },
                        }}
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
};


export default TvShowDetails;
