
import '../ComponentsCSS/Rodape.css'

const Footer = () => {
  return (
    <footer>
      <div className='container_rodape'>
        <div>
          <p>Somos o assunto quando é qualidade para assistir filmes e séries online.</p>
        </div>
      </div>
      <div className='container_subRodape'>
        <p>NetMovies - © 2024 All rights reserved | O Melhor Site de Filmes e Séries Online</p>
      </div>
      <div className='container_l'>
        <a href="/Dmca">DMCA</a>
        {/* <a href="https://discord.gg/TmQfvy6W">Acesse nosso Discord</a> */}
      </div>
    </footer>
  )
}

export default Footer
