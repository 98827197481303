import React from 'react'

const Order = () => {
    return (
        <div className='order-commets'>
            {(function () {
                // DON'T EDIT BELOW THIS LINE
                var d = document,
                    s = d.createElement('script');
                s.src = 'https://https-netmovies-win.disqus.com/embed.js';
                s.setAttribute('data-timestamp', +new Date());
                (d.head || d.body).appendChild(s);
            })()}
            <div id='disqus_thread'></div>
        </div>
    )
}

export default Order