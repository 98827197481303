import styled from "styled-components";
import { Swiper } from "swiper/react";

export const StyledSlide = styled(Swiper)`

.swiper-button-next,.swiper-button-prev  {
    color: red;
}
`;

