import { useEffect, useState } from 'react';
import { BASE_PRO } from '../../env';

export const categories = ['netflix', 'ação', 'animação', 'aventura', 'biografia', 'comédia', 'drama', 'Ficção científica', 'romance', 'suspense', 'terror'];

const HomeService = () => {

    const [dataMovie, setDataMovie] = useState();
    const [dataCategory, setDataCategory] = useState();

    const fetchDataMovie = async (page = 1, limit = 10) => {
        try {
            const res = await fetch(`${BASE_PRO}/movies?page=${page}&limit=${limit}`, {
                method: 'GET',
            });

            const movieData = await res.json();
            setDataMovie(movieData);

            localStorage.setItem('dataMovie', JSON.stringify({ data: movieData, lastUpdated: Date.now() }));
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    const fetchDataCategory = async (category) => {
        try {
            const res = await fetch(`${BASE_PRO}/movies?category=${category}&page=1&limit=10`, {
                method: 'GET',
            });

            const movieData = await res.json();
            return movieData;
        } catch (error) {
            console.error(`Erro ao buscar dados da categoria ${category}:`, error);
            return null;
        }
    };


    useEffect(() => {
        const fetchAllCategoriesData = async () => {
            const allCategoriesData = {};
            let dataFromLocalStorage = JSON.parse(localStorage.getItem('dataCategory'));
            if (!dataFromLocalStorage) {
                dataFromLocalStorage = {};
            }

            for (const category of categories) {
                let data;
                if (dataFromLocalStorage[category]) {

                    const lastUpdated = dataFromLocalStorage[category].lastUpdated;
                    if (Date.now() - lastUpdated < 3600000) {
                        data = dataFromLocalStorage[category].data;
                    } else {
                        data = await fetchDataCategory(category);
                    }
                } else {
                    data = await fetchDataCategory(category);
                }
                allCategoriesData[category] = data;
            }

            setDataCategory(allCategoriesData);

            localStorage.setItem('dataCategory', JSON.stringify(allCategoriesData));
        };

        fetchAllCategoriesData().catch((error) => {
            console.error('Erro ao buscar dados de várias categorias:', error);
        });

        fetchDataMovie(1, 10);
    }, []);

    function formatDate(date) {
        const dateObject = new Date(date);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // const getCacheData = () => {
    //     const cachedMovieData = JSON.parse(localStorage.getItem('dataMovie'));
    //     const cachedCategoryData = JSON.parse(localStorage.getItem('dataCategory'));

    //     if (cachedMovieData && cachedCategoryData && cachedMovieData.data && Object.keys(cachedCategoryData).length > 0) {
    //         return {
    //             dataMovie: cachedMovieData.data,
    //             dataCategory: cachedCategoryData,
    //         };
    //     } else {
    //         return {
    //             dataMovie: dataMovie,
    //             dataCategory: dataCategory,
    //         };
    //     }
    // };



    return {
        dataMovie,
        dataCategory,
        formatDate
    };
}

export default HomeService;
