import { useEffect, useState } from 'react';
import { BASE_PRO } from '../../env';

const CategoryService = (currentPage, perPage) => {
    const [dataCategory, setDataCategory] = useState(null);
    const [totalPage, setTotalPage] = useState(null);
    const [error, setError] = useState(null);

    const fetchDataCategoryMovie = async () => {
        try {
            const res = await fetch(`${BASE_PRO}/tv-shows?page=${currentPage}&limit=${perPage}`, {
                method: 'GET',
            });

            if (!res.ok) {
                throw new Error('Failed to fetch data');
            }

            const categoryData = await res.json();
            setDataCategory(categoryData);
            setTotalPage(categoryData.totalPages);
            setError(null);
        } catch (error) {
            console.error('Error fetching category data:', error);
            setError('Error fetching data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchDataCategoryMovie();
    }, [currentPage, perPage]);

    return {
        dataCategory,
        totalPage,
        error
    };
}

export default CategoryService;
