import { useState } from 'react';
import CategoryService from './tv-show.service';

export const useTvShowParams = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 12;

    const { dataCategory, totalPage } = CategoryService(currentPage, perPage);

    const handlePageClick = (pageNumber) => setCurrentPage(pageNumber);

    return {
        dataCategory,
        handlePageClick,
        perPage,
        currentPage,
        totalPage
    }
}
