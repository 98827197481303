import { lazy, Suspense } from 'react';
import { Route, Routes } from "react-router-dom";
import MenuMobile from './components/MenuMobile';
import Menu from './components/Menu/menu.view';
import Footer from './components/Footer';
import './ComponentsCSS/responsivo.css';
import './App.css';
import Home from './Pags/home/Home';
import Order from './Pags/Order';
import MovieDetails from './Pags/MoviePlay/movie-details.view';
import TvShowDetails from './Pags/TvShow/tv-show-view';
import TvShowPlayView from './Pags/TvShowPlay/tv-show-play.view';


const CategoryContainer = lazy(() => import('./Pags/Category/category-view'));

const DMCA = lazy(() => import('./Pags/Dmca'));
const Error404 = lazy(() => import('./Pags/Error404'));

window.addEventListener("keydown", function (event) {
  if (
    (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
    (event.ctrlKey && event.keyCode === 85) ||
    (event.ctrlKey && event.keyCode === 83) ||
    (event.ctrlKey && event.keyCode === 74)
  ) {
    event.preventDefault();
  }
});
window.addEventListener("contextmenu", function (event) {
  event.preventDefault();
});


function App() {

  return (
    <div>
      <>
        <MenuMobile />
        <Menu />
        <Routes>
          <Route path='/' element={<><Home /></>} />
          <Route
            path="/series"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <>
                  <>
                    <TvShowDetails />
                  </>
                </>
              </Suspense>
            }
          />
          {/* <Route
            path="/Canais"
            element={
              <Suspense fallback={<>Loading...</>}>
                <>
                  <PagCanal />
                </>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/movie/:id"
            element={
              <Suspense fallback={<>Loading...</>}>
                <MovieDetails />
              </Suspense>
            }
          />
          <Route
            path="/serie/:id"
            element={
              <Suspense fallback={<>Loading...</>}>
                <TvShowPlayView />
              </Suspense>
            }
          />
          <Route
            exact
            path="/categoria/:id"
            element={
              <Suspense fallback={<>Loading...</>}>
                <CategoryContainer />
              </Suspense>
            }
          />
          <Route
            path="/Dmca"
            element={
              <Suspense fallback={<>Loading...</>}>
                <DMCA />
              </Suspense>
            }
          />
          <Route
            path="/nm/pedidos"
            element={
              <Suspense fallback={<>Loading...</>}>
                <Order />
              </Suspense>
            }
          />
          <Route path="/*" element={<Error404 />}
          />
        </Routes>

      </>
      <>
        <Footer />
      </>
    </div>
  );
}

export default App;
