import React, { useState, useEffect } from 'react';
import '../../ComponentsCSS/MeioFilmes.css';
import '../../ComponentsCSS/responsivo.css';
import { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import * as S from '../style';
import '../../ComponentsCSS/home.css';
import HomeService, { categories } from './home.service';
import './home.css'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isWideScreen = window.innerWidth > 640;

  const { dataMovie, dataCategory, formatDate } = HomeService();

  // const { dataMovie, dataCategory } = getCacheData();

  useEffect(() => {
    // const cachedData = getCacheData();
    // if (cachedData.dataMovie.length > 0 && Object.keys(cachedData.dataCategory).length > 0) {
    //   setIsLoading(false);
    // } else 
    if (dataMovie && dataCategory) {
      setIsLoading(false);
    }
  }, [dataMovie, dataCategory]);


  const renderCategorySlides = (categoryData) => {
    return (
      <S.StyledSlide
        breakpoints={{
          200: { slidesPerView: 4 },
          640: { slidesPerView: 7 },
          900: { slidesPerView: 8 },
          1020: { slidesPerView: 10 },
          1280: { slidesPerView: 8 },
        }}
        spaceBetween={70}
        pagination={{
          type: 'progressbar',
        }}
        navigation={isWideScreen}
        modules={[Pagination, Navigation]}
        className='mySwiper'
      >
        {categoryData ? (
          categoryData.movies.map((movie) => (
            <SwiperSlide key={movie.id}>
              <div className='container_detalhesPai'>
                <div className='container_detalhes'>
                  <>
                    <p>{movie.nome}</p>
                  </>
                  <>
                    <a href={`/movie/${movie.nome}`}>
                      <img className='grid-item' src={movie.img} alt='capaFilme' />
                    </a>
                    <p className='movieRecent'>{formatDate(movie.createdAt)}</p>
                  </>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : null}
      </S.StyledSlide>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className='loading'>
          <div className="netflix-loading">
            <span>N</span>
            <span>e</span>
            <span>t</span>
            <span>M</span>
            <span>o</span>
            <span>v</span>
            <span>i</span>
            <span>e</span>
            <span>s</span>
          </div>
        </div>
      ) : (
        <div className='Ultimos'>
          <>
            <h2>Últimos Adicionados</h2>
            <S.StyledSlide
              breakpoints={{
                200: { slidesPerView: 4 },
                640: { slidesPerView: 7 },
                900: { slidesPerView: 8 },
                1020: { slidesPerView: 10 },
                1280: { slidesPerView: 8 },
              }}
              spaceBetween={70}
              pagination={{
                type: 'progressbar',
              }}
              navigation={isWideScreen}
              modules={[Pagination, Navigation]}
              className='mySwiper'
            >
              {dataMovie ? (
                dataMovie.movies.map((movie) => (
                  <SwiperSlide key={movie.id}>
                    <div className='container_detalhesPai'>
                      <div className='container_detalhes'>
                        <>
                          <p>{movie.nome}</p>
                        </>
                        <>
                          <a href={`/movie/${movie.nome}`}>
                            <img className='grid-item' src={movie.img} alt='capaFilme' />
                          </a>
                          <p className='movieRecent'>{formatDate(movie.createdAt)}</p>
                        </>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : null}
            </S.StyledSlide>
          </>
          {categories && dataCategory && categories.map((category) => (
            <div key={category} className='p-2'>
              <h2 className='my-4 font-semibold'>{category}</h2>
              {renderCategorySlides(dataCategory[category])}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Home;
