import React, { useState } from 'react'
import { MenuService } from './menu.service'

export const MenuParams = () => {
    const [searchIconActive, setSearchIconActive] = useState(false);
    const [movieName, setMovieName] = useState();

    function searchbar() {
        if (!searchIconActive) {
            setSearchIconActive(true);
        } else {
            setSearchIconActive(false);
        }
    }

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        if (searchTerm.trim() === '') {
            setMovieName([]);
        } else {
            setMovieName(searchTerm)
        }
        setSearchIconActive(true);
    };


    const { searchRes } = MenuService(movieName)





    return {
        searchbar,
        searchIconActive,
        handleSearch,
        movieName,
        searchRes
    }
}
