/* eslint-disable react/prop-types */
import { useState } from 'react';
import seta from '../../assents/seta.svg';
// import att from '../assents/refresh.png';
import { Context } from '../../context/GlobalState';
import { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import * as S from '../style';
import "../../ComponentsCSS/PagUnica.css"
import MovieService from './movie-details.service';
import { useParams } from 'react-router-dom';


const MovieDetails = () => {
  const id = useParams();
  const { dataMovie } = MovieService(id.id);

  const isWideScreen = window.innerWidth > 640;

  const [synopsisVisible, setSynopsisVisible] = useState(false);
  const [similar, setSimilar] = useState([]);
  const [list, setList] = useState();


  document.title = 'NetMovies - ' + id.id

  function toggleSinopse() {
    setSynopsisVisible(!synopsisVisible);
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (categoria?.toLowerCase().includes('ação')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('ação')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('animação')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('animação')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('aventura')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('aventura')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('biografia')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('biografia')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('comédia')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('comédia')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('f-Científica')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('f-Científica')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('romance')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('romance')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('suspense')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('suspense')
  //       ));
  //     } else if (categoria?.toLowerCase().includes('terror')) {
  //       setLista(allMovie.filter((category) =>
  //         category.categoria.toLowerCase().includes('terror')
  //       ));
  //     }

  //     update();
  //     document.title = 'NetMovies - ' + nomeDaPagina;
  //   }, 100);

  //   if (timer >= 100) {
  //     clearTimeout(timer);
  //   } else {
  //     return () => clearTimeout(timer);
  //   }
  // }, [allMovie, categoria, nomeDaPagina, nome, setSemelhantes, update]);

  return (
    <div className='pag_filme'>
      {dataMovie ?
        <div className='container_movie'>
          <div className='container_img'>
            <img src={dataMovie.img} className='justify-center items-center text-center flex m-auto' alt='Capa' />
            <div className='sub_movie'>
              <p>{dataMovie.data}</p>
              <p>{dataMovie.categoria}</p>
              <p>Duração: {dataMovie.tempo}</p>
              <p>Aprovação: {dataMovie.approval}</p>
            </div>
          </div>
          <div>
            <div className='embed_frame'>
              <h1>{dataMovie.nome}</h1>
              {dataMovie.link.includes("embedder") || dataMovie.link.includes("embed") || dataMovie.link.includes("embedy") || dataMovie.link.includes("filemoon") || dataMovie.link.includes("streamtape") ? (
                <div className="iframe-container">
                  <iframe
                    title="EmbedderContainer"
                    src={dataMovie.link}
                    frameborder="0"
                    height="100%"
                    width="100%"
                    allowfullscreen="allowfullscreen"
                    allowtransparency
                  ></iframe>
                </div>
              ) : (
                <a href={dataMovie.link}>
                  <button className='hover:text-red-500'>Assistir</button>
                </a>
              )}
            </div>

            <div >
              <div className='seta'>
                <p className='sinop'>Sinopse</p>
                <div>
                  <img onClick={toggleSinopse} alt='sinopse' src={seta} />
                </div>
              </div>
              <h2 className={!synopsisVisible ? 'sinopseOn' : 'sinopseOF'}>
                {dataMovie.sinopse}
              </h2>
            </div>
          </div>
        </div> : <p>Carregando..</p>}

      {/* <div className='container_pagUnicaFilmes'>
        <>
          <h2 className='container_refresh'>
            Você pode se interessar
            <span onClick={update}>
              <img src={att} alt='update' />
            </span>
          </h2>
        </> */}

      {/* <>
            <S.StyledSlide
              breakpoints={{
                200: { slidesPerView: 4 },
                640: { slidesPerView: 3 },
                900: { slidesPerView: 8 },
                1020: { slidesPerView: 5 },
                1280: { slidesPerView: 8 },
              }}
              spaceBetween={70}
              pagination={{
                type: 'progressbar',
              }}
              navigation={isWideScreen}
              modules={[Pagination, Navigation]}
              className='mySwiper'>
              {semelhantes ? (
                semelhantes.slice(0, 10).map((movie) => (
                  <SwiperSlide key={movie.id}>
                    <div className='container_detalhesPai'>
                      <div className='container_detalhes'>
                        <div>
                          <p>{movie.nome}</p>
                        </div>
                        <div>
                          <a href={`/Movie/${movie.nome}/`}>
                            <img className='grid-item' src={movie.img} alt='capaFilme' />
                          </a>
                          <p>{movie.data}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <span>Carregando... Aguarde</span>
              )}
            </S.StyledSlide>
          </> */}

      {/* </div> */}
      {/* 
      {(function () {
        // DON'T EDIT BELOW THIS LINE
        var d = document,
          s = d.createElement('script');
        s.src = 'https://https-netmovies-win.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
      })()}
      <div id='disqus_thread'></div> */}
    </div >
  )
}

export default MovieDetails