import { useEffect, useState } from 'react';
import { BASE_PRO } from '../../env';

const MovieService = (name) => {
    const [dataMovie, setDataMovie] = useState(null);
    
    const fetchDataDetailsMovie = async () => {
        try {
            const res = await fetch(`${BASE_PRO}/movies?movieName=${name}`, {
                method: 'GET',
            });
            
            const movieData = await res.json();
            console.log('aq', movieData.movies[0]);
            setDataMovie(movieData.movies[0]);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    useEffect(() => {
        fetchDataDetailsMovie();
    }, [name]); // Executa quando o nome do filme muda

    if (dataMovie) {
        console.log('dataMovie', dataMovie.nome);
    }

    return {
        dataMovie
    };
}

export default MovieService;
