import { useState, useEffect } from 'react'
import { BASE_PRO } from '../../env';

export const MenuService = (movieName) => {
    const [searchRes, setSearchRes] = useState([]);

    const fetchData = async () => {
        try {
            const movieRes = await fetch(`${BASE_PRO}/movies?page=1&limit=10&movieName=${movieName}`);
            const tvShowRes = await fetch(`${BASE_PRO}/tv-shows?page=1&limit=10&tvshowname=${movieName}`);

            const movieData = await movieRes.json();
            const tvShowData = await tvShowRes.json();

            console.log('movieData:', movieData);
            console.log('tvShowData:', tvShowData);

            // Combine movie and TV show results
            const combinedResults = [...movieData.movies, ...tvShowData.tvShows];

            console.log('combinedResults:', combinedResults);

            setSearchRes(combinedResults);
        } catch (error) {
            console.error('Error fetching search data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [movieName]);

    console.log('searchRes:', searchRes);

    return {
        searchRes
    };
};
