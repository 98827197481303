import React, { createContext, useState } from "react";
import PropTypes from "prop-types";


const Context = createContext();

const CACHE_KEY = 'cachedData';

const GlobalState = ({ children }) => {
    const [data, setData] = useState(null);
    const [menuOn, setMenuOn] = useState(false);


    // const fetchData = async () => {
    //     try {
    //         const baseUrl = db[0].BASE_DB; // Certifique-se de que db está definido corretamente

    //         const headers = {
    //             'x-api-key': '45A495A3G4A7OGHA4154f5GWA4G54GWE10GE64GWGGE'
    //         };

    //         const [resDB, resTv, resCanal] = await Promise.all([
    //             fetch(baseUrl + '/movies', { headers }),
    //             fetch(baseUrl + '/tv', { headers }),
    //             fetch(baseUrl + '/canal', { headers })
    //         ]);

    //         const [dataDB, dataTv, dataCanal] = await Promise.all([
    //             resDB.json(),
    //             resTv.json(),
    //             resCanal.json()
    //         ]);

    //         setData({
    //             allMovie: dataDB,
    //             tv: dataTv,
    //             canal: dataCanal
    //         });
    //     } catch (error) {
    //         console.error('Erro ao buscar dados:', error);

    //     }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, [data]);


    console.log('data', data);
    // const fetchDataAndSetData = useCallback(async () => {
    //     try {
    //         const result = await fetchData();

    //         if (result) {
    //             setData(result);
    //             localStorage.setItem(CACHE_KEY, JSON.stringify(result));
    //         }

    //     } catch (error) {
    //         console.log("Ocorreu um erro, tente novamente mais tarde");
    //     }
    // }, []);

    // useEffect(() => {
    //     // Check if cached data exists
    //     const cachedData = localStorage.getItem(CACHE_KEY);
    //     if (cachedData) {
    //         setData(JSON.parse(cachedData));
    //     }

    //     // Fetch new data
    //     fetchDataAndSetData();

    //     // Schedule periodic data refresh (e.g., every 30 minutes)
    //     const refreshInterval = setInterval(fetchDataAndSetData, 30 * 60 * 1000);

    //     return () => {
    //         clearInterval(refreshInterval);
    //     };
    // }, [fetchDataAndSetData]);

    // if (!data) {
    //     return (
    //         <div className="loading">
    //             <img src={loading} alt="loading" />
    //             <p>Aguarde... a magia está prestes a começar.</p>
    //         </div>
    //     );
    // }

    return (
        <Context.Provider
            value={{
                setMenuOn,
                menuOn,
            }}
        >
            {children}
        </Context.Provider>
    );
};

GlobalState.propTypes = {
    children: PropTypes.node.isRequired,
};

export { Context, GlobalState };
