import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import '../../ComponentsCSS/PagUnica.css'
import '../../ComponentsCSS/PagUnicaSerie.css'
import '../../ComponentsCSS/responsivo.css'

import seta from '../../assents/seta.svg'
import att from '../../assents/refresh.png'
import { Context } from '../../context/GlobalState';

import { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import * as S from '../../Pags/style';
import { TvShowPlayParams } from './tv-show-play.params';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


const TvShowPlayView = () => {

  const { id } = useParams();

  const { dataTvShow } = TvShowPlayParams(id);
  console.log('dataTvShow', dataTvShow);
  const isWideScreen = window.innerWidth > 640;
  const [sinopseVisivel, setSinopseVisivel] = useState(false);
  const [semelhantes, setSemelhantes] = useState();
  const [lista, setLista] = useState()
  const [temporadaExibida, setTemporadaExibida] = useState(0);
  const [selectedSeason, setSelectedSeason] = useState("");


  const {
    _id,
    nome,
    data,
    categoria,
    tempo,
    approval,
    sinopse,
    img,
    temporadas,
    createdAt,
    updatedAt,
    __v,
    allTemporadas
  } = dataTvShow || {};

  function exibirTemporada(seasonNumber) {
    setTemporadaExibida(temporadaExibida === seasonNumber ? 0 : seasonNumber);
  }

  const handleChange = (event) => {
    setSelectedSeason(event.target.value);
    exibirTemporada(event.target.value);
  };

  function toggleSinopse() {
    setSinopseVisivel(!sinopseVisivel);
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {

  //     if (categoria?.toLowerCase().includes("ação")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("ação")))
  //     }
  //     else if (categoria?.toLowerCase().includes("adolescência")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("adolescência")))
  //     }
  //     else if (categoria?.toLowerCase().includes("animação")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("animação")))
  //     }
  //     else if (categoria?.toLowerCase().includes("aventura")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("aventura")))
  //     }
  //     else if (categoria?.toLowerCase().includes("biografia")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("biografia")))
  //     }
  //     else if (categoria?.toLowerCase().includes("comédia")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("comédia")))
  //     }
  //     else if (categoria?.toLowerCase().includes("drama")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("drama")))
  //     }
  //     else if (categoria?.toLowerCase().includes("fantasia")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("fantasia")))
  //     }
  //     else if (categoria?.toLowerCase().includes("f-Científica")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("f-Científica")))
  //     }
  //     else if (categoria?.toLowerCase().includes("romance")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("romance")))
  //     }
  //     else if (categoria?.toLowerCase().includes("suspense")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("suspense")))
  //     }
  //     else if (categoria?.toLowerCase().includes("terror")) {
  //       setLista(tv.filter((category) => category.categoria.toLowerCase().includes("terror")))
  //     }
  //     update();
  //     //Site + name movie
  //     document.title = "NetMovies - " + nome;
  //   }, 100);

  //   if (timer >= 100) {
  //     clearTimeout(timer);
  //   } else {
  //     return () => clearTimeout(timer);
  //   }
  // }, [tv, categoria, nome, setSemelhantes, update]);

  //Fisher-Yates
  // function shuffleArray(array) {
  //   const newArray = [...array];
  //   for (let i = newArray.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  //   }
  //   return newArray;
  // }

  // function update() {
  //   if (lista?.length > 0) {
  //     const shuffledList = shuffleArray(lista);
  //     setSemelhantes(shuffledList);
  //   }
  // }
  const CustomFormControl = styled(FormControl)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: '1.2rem', // Mudança do tamanho da fonte
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
    '&.text-red-500': { // Adição da classe para cor vermelha
      color: 'red',
    },
  }));

  return (
    <div className='pag_serie'>
      <div className='container_movie'>
        <div className='container_img'>
          <img className='justify-center items-center text-center flex m-auto' src={img} alt='Capa' />
          <div className='sub_movie'>
            <p>{data}</p>
            <p>{categoria}</p>
            <p>Temporadas: {tempo}</p>
            <p>Aprovação: {approval}</p>
          </div>
        </div>
        <div>
          <div className='sub_movie'>
            <h1>{nome}</h1>
          </div>
          <div className='seta'>
            <p className='sinop'>Sinopse</p>
            <div>
              <img onClick={toggleSinopse} alt='sinopse' src={seta} />
            </div>
          </div>
          <h2 className={sinopseVisivel ? 'sinopseOn' : 'sinopseOF'}>{sinopse}</h2>
          <div>
            <div className='mt-5'>
              <CustomFormControl fullWidth>
                <FormControl fullWidth variant="outlined"
                  className={`border rounded-xl cursor-pointer ml-3 text-gray-800`}
                >
                  <InputLabel id="select-temporada-label" className='text-white'>Selecionar Temporada</InputLabel>
                  <Select
                    value={selectedSeason}
                    label="Selecionar Temporada"
                    onChange={handleChange}

                  >
                    {temporadas && temporadas.map((temporada, index) => (
                      <MenuItem key={index} value={index + 1}>{temporada.nomeTemporada}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CustomFormControl>
            </div>

            <div>
              {allTemporadas ? allTemporadas.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temporada Completa:</p></div>
                  <span><a href={ser.link}>Assistir</a></span>
                  <p className='player'>Player Recomendado: Streamtape</p>
                </div>
              )) : []}
            </div>

            <div>
              {temporadas && temporadaExibida === 1 && temporadas[0].episodios.map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    {episodio.link.includes("embedder") || episodio.link.includes("embed") || episodio.link.includes("embedy") || episodio.link.includes("filemoon") ? (
                      <iframe
                        title="EmbedderContainer"
                        src={episodio.link}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        allowfullscreen="allowfullscreen"
                        allowtransparency
                      ></iframe>
                    ) : (
                      <a href={episodio.link}>
                        <button>Assistir</button>
                      </a>
                    )}
                  </span>
                </div>
              ))}
            </div>
            {/* Temporada 2 */}
            <div>
              {temporadas && temporadaExibida === 2 && temporadas[1].episodios.map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    {episodio.link.includes("embedder") || episodio.link.includes("embed") || episodio.link.includes("embedy") || episodio.link.includes("filemoon") ? (
                      <iframe
                        title="EmbedderContainer"
                        src={episodio.link}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        allowfullscreen="allowfullscreen"
                        allowtransparency
                      ></iframe>
                    ) : (
                      <a href={episodio.link}>
                        <button>Assistir</button>
                      </a>
                    )}
                  </span>
                </div>
              ))}
            </div>
            {/* Temporada 3 */}
            <div>
              {temporadas && temporadaExibida === 3 && temporadas[2].episodios.map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    {episodio.link.includes("embedder") || episodio.link.includes("embed") || episodio.link.includes("embedy") || episodio.link.includes("filemoon") ? (
                      <iframe
                        title="EmbedderContainer"
                        src={episodio.link}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        allowfullscreen="allowfullscreen"
                        allowtransparency
                      ></iframe>
                    ) : (
                      <a href={episodio.link}>
                        <button>Assistir</button>
                      </a>
                    )}
                  </span>
                </div>
              ))}
            </div>
            {/* Temporada 4 */}
            <div>
              {temporadas && temporadaExibida === 4 && temporadas[3].episodios.map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    {episodio.link.includes("embedder") || episodio.link.includes("embed") || episodio.link.includes("embedy") || episodio.link.includes("filemoon") ? (
                      <iframe
                        title="EmbedderContainer"
                        src={episodio.link}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        allowfullscreen="allowfullscreen"
                        allowtransparency
                      ></iframe>
                    ) : (
                      <a href={episodio.link}>
                        <button>Assistir</button>
                      </a>
                    )}
                  </span>
                </div>
              ))}
            </div>
            {/* Temporada 5 */}
            <div>
              {temporadas && temporadaExibida === 5 && temporadas[4].episodios.map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    {episodio.link.includes("embedder") || episodio.link.includes("embed") || episodio.link.includes("embedy") || episodio.link.includes("filemoon") ? (
                      <iframe
                        title="EmbedderContainer"
                        src={episodio.link}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        allowfullscreen="allowfullscreen"
                        allowtransparency
                      ></iframe>
                    ) : (
                      <a href={episodio.link}>
                        <button>Assistir</button>
                      </a>
                    )}
                  </span>
                </div>
              ))}
            </div>
            {/* Temporada 6 */}
            <div>
              {temporadas && temporadaExibida === 6 && temporadas[5].episodios.map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    {episodio.link.includes("embedder") || episodio.link.includes("embed") || episodio.link.includes("embedy") || episodio.link.includes("filemoon") ? (
                      <iframe
                        title="EmbedderContainer"
                        src={episodio.link}
                        frameborder="0"
                        height="100%"
                        width="100%"
                        allowfullscreen="allowfullscreen"
                        allowtransparency
                      ></iframe>
                    ) : (
                      <a href={episodio.link}>
                        <button>Assistir</button>
                      </a>
                    )}
                  </span>
                </div>
              ))}
            </div>
            {/* <div>
              {temporadaExibida === 6 && temporadas.reduce((episodios, temporada) => episodios.concat(temporada.episodios), []).map((episodio, index) => (
                <div key={index} className='container_subTemporada'>
                  <p>Ep: {index + 1} {episodio.nomeEpisodio}</p>
                  <span>
                    <a href={episodio.link}>Assistir</a>
                  </span>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
      {/* <div>
        <div>
          <h2 className='container_refresh'>
            Você pode se interessar
            <span onClick={update}>
              <img src={att} alt='update' />
            </span>
          </h2>
        </div>
        <div>
          <div>
            <S.StyledSlide
              breakpoints={{
                200: { slidesPerView: 4 },
                640: { slidesPerView: 7 },
                900: { slidesPerView: 8 },
                1020: { slidesPerView: 8 },
                1280: { slidesPerView: 8 },
              }}
              spaceBetween={70}
              pagination={{
                type: 'progressbar',
              }}
              navigation={isWideScreen}
              modules={[Pagination, Navigation]}
              className='mySwiper'
            >
              {semelhantes ? (
                semelhantes.slice(0, 15).map((movie) => (
                  <SwiperSlide key={movie.id}>
                    <div className='container_detalhesPai'>
                      <div className='container_detalhes'>
                        <div>
                          <p>{movie.nome}</p>
                        </div>
                        <div>
                          <a href={`/TvShow/${movie.nome}/`}>
                            <img className='grid-item' src={movie.img} alt='capaFilme' />
                          </a>
                          <p>{movie.data}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <span>Carregando... Aguarde</span>
              )}
            </S.StyledSlide>
          </div>
          <div id="container-1649a27bd526c8e9e7d9a2cacbb29015"></div>
        </div>
      </div> */}
      {/* {

        (function () { // DON'T EDIT BELOW THIS LINE
          var d = document, s = d.createElement('script');
          s.src = 'https://https-netmovies-win.disqus.com/embed.js';
          s.setAttribute('data-timestamp', +new Date());
          (d.head || d.body).appendChild(s);
        })()
      }
      <div id="disqus_thread"></div> */}
    </div>
  )
}

export default TvShowPlayView